import {
  DatabaseOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  // {
  //   key: "verifikasi-user",
  //   path: `${ADMIN_PREFIX_PATH}/verifikasi-user`,
  //   title: "Verifikasi User",
  //   icon: DatabaseOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "list-dealer",
    path: `${ADMIN_PREFIX_PATH}/list-dealer`,
    title: "Data Dealer",
    icon: DatabaseOutlined,
    permissions: ['CREATE_DEALERS', 'GET_DEALERS'],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-karyawan",
    path: `${ADMIN_PREFIX_PATH}/master-karyawan`,
    title: "Data Karyawan",
    icon: DatabaseOutlined,
    permissions: ['CREATE_MEMBER', 'DELETE_MEMBER','GET_MEMBER','IMPORT_MEMBER','UPDATE_MEMBER'],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-bank",
    path: `${ADMIN_PREFIX_PATH}/master-bank`,
    title: "Data Bank",
    icon: DatabaseOutlined,
    permissions: ['CREATE_BANKS', 'DELETE_BANKS','GET_BANKS','UPDATE_BANKS'],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-barang",
    path: `${ADMIN_PREFIX_PATH}/master-barang`,
    title: "Data Barang",
    icon: DatabaseOutlined,
    permissions: [
      'CREATE_ITEM', 
      'GET_ITEMS',
      'IMPORT_ITEM',
      'UPDATE_ITEM',
      'CREATE_ITEM_CATEGORIES',
      'GET_ITEM_CATEGORIES',
      'UPDATE_ITEM_CATEGORIES',
      'CREATE_ITEM_BRAND',
      'GET_ITEM_BRANDS',
      'UPDATE_ITEM_BRAND',
      'CREATE_ITEM_TYPES',
      'DELETE_ITEM_TYPES',
      'GET_ITEM_TYPES',
      'RESTORE_ITEM_TYPES',
      'UPDATE_ITEM_TYPES',
      'CREATE_ITEM_PRICES',
      'DELETE_ITEM_PRICES',
      'GET_ITEM_PRICES',
      'RESTORE_ITEM_PRICES',
      'UPDATE_ITEM_PRICES',
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-hr",
    path: `${ADMIN_PREFIX_PATH}/master-hr`,
    title: "Data HR",
    icon: DatabaseOutlined,
    // permissions: ['CREATE_BANKS', 'DELETE_BANKS','GET_BANKS','UPDATE_BANKS'],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-manager",
    path: `${ADMIN_PREFIX_PATH}/master-manager`,
    title: "Data Manager",
    icon: DatabaseOutlined,
    // permissions: ['CREATE_BANKS', 'DELETE_BANKS','GET_BANKS','UPDATE_BANKS'],
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
